import React, { Component } from 'react';
import Slider from 'react-slick';

import styles from './phoneSlider.module.css';

import screenshot1 from '../assets/images/screenshots/rf-screenshot-discover.jpg';
import screenshot2 from '../assets/images/screenshots/rf-screenshot-discover-2.jpg';
import screenshot3 from '../assets/images/screenshots/rf-screenshot-reviews.jpg';
import screenshot4 from '../assets/images/screenshots/rf-screenshot-recommend.jpg';
import screenshot5 from '../assets/images/screenshots/rf-screenshot-lists.jpg';
import screenshot6 from '../assets/images/screenshots/rf-screenshot-profile.jpg';
import screenshot7 from '../assets/images/screenshots/rf-screenshot-profile-2.jpg';
import screenshot8 from '../assets/images/screenshots/rf-screenshot-notifications.jpg';
import screenshot9 from '../assets/images/screenshots/rf-screenshot-activity.jpg';

const screenshots = [
  screenshot8,
  screenshot9,
  screenshot1,
  screenshot2,
  screenshot3,
  screenshot4,
  screenshot6,
  screenshot5,
  screenshot7
];

export default class PhoneSlider extends Component {
  renderScreenShot(screenshot) {
    return (
      <div style={{ maxWidth: 150 }}>
        <img src={screenshot} className={styles.carousalImage} />
      </div>
    );
  }

  render() {
    var settings = {
      autoplay: true,
      dots: false,
      speed: 800,
      autoplaySpeed: 2500,
      infinite: true,
      slidesToShow: 5,
      pauseOnHover: false,
      responsive: [
        {
          breakpoint: 1320,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 820,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    };
    return (
      <div className={this.props.isMobile ? styles.isMobile : styles.isDesktop}>
        <div className={styles.headerWrapper}>
          <h2>The best recommendations come from friends</h2>
          <p style={{ maxWidth: 560, marginLeft: 'auto', marginRight: 'auto' }}>
            Connect with friends and get real recommendations from the people
            who know you best.
          </p>
        </div>

        <div
          style={{ position: 'relative', marginBottom: 100, width: '100%' }}
          className={this.props.isMobile ? styles.isMobile : styles.isDesktop}>
          <div className={styles.carousalPhoneContainer}>
            <div className={styles.phone} />
          </div>
          <Slider {...settings}>
            {screenshots.map(screenshot => this.renderScreenShot(screenshot))}
          </Slider>
        </div>
      </div>
    );
  }
}
