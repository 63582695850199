import React from 'react';
import Typed from 'react-typed';

import styles from './hero.module.css';
import screenshot from '../assets/images/splash.jpg';
import previewVideo from '../assets/videos/app-preview.mp4';

export default props => (
  <div className={styles.hero}>
    <div className={styles.wrapper}>
      <div className={styles.phoneContainer}>
        <div className={styles.phone}>
          <video
            className={styles.video}
            width='296'
            height='526'
            muted
            autoPlay='true'
            loop
            poster={screenshot}
            preload='none'>
            <source src={previewVideo} type='video/mp4' />
          </video>
        </div>
      </div>
      <div className={styles.body}>
        <h1 className={styles.title}>The social recommendation app for</h1>
        <Typed
          strings={['movies', 'TV shows', 'games', 'podcasts']}
          typeSpeed={60}
          backSpeed={10}
          loop
          className={styles.typedText}
        />
      </div>
    </div>
  </div>
);
