import React from 'react';

import Header from '../components/header';
import Hero from '../components/hero';
import MacbookSection from '../components/macbookSection';
import PhoneSliderSection from '../components/phoneSlider';
import Footer from '../components/footer';
import ActionSection from '../components/actionSection';
import MobileFooter from '../components/mobileFooter';
import SEO from '../components/seo/SEO';

export default () => (
  <div className='application'>
    <SEO />
    <Header />
    <Hero />
    <PhoneSliderSection isMobile={true} />
    <MacbookSection />
    <PhoneSliderSection isMobile={false} />
    <ActionSection />
    <Footer />
    <MobileFooter />
  </div>
);
